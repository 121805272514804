.notif-feed__popover {
  --el-popover-padding: 16px;
}

.notif-feed__container {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
}

.notif-feed__btn .el-badge {
  --el-badge-size: 16px;
  --el-badge-padding: 4px;
  --el-badge-font-size: 10px;
}

.notif-feed__btn .el-badge__content {
  background-color: red;
  min-width: 16px;
  font-weight: 700;
}

.notif-feed__btn .el-badge__content.is-fixed {
  top: 5px;
  right: 10px;
}

.notif-feed__head {
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 0 8px;
  display: flex;
}

.notif-feed__center-link {
  cursor: pointer;
  color: #5229a1;
  font-size: 12px;
  text-decoration: underline;
  transition: all .2s;
}

.notif-feed__center-link:hover {
  opacity: .8;
}

.notif-feed__all-checked {
  cursor: pointer;
  color: #5229a1;
  transition: all .2s;
}

.notif-feed__all-checked:hover {
  opacity: .8;
}

.notif-feed__all-checked .ico {
  width: 16px;
  height: 16px;
}

.notif-feed__list {
  flex-grow: 1;
}

.notif-feed__wrapper {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  max-height: 70vh;
  padding-right: 16px;
  display: flex;
  overflow-y: auto;
}

.notif-feed__wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.notif-feed__wrapper::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 4px;
  height: 0;
}

.notif-feed__wrapper::-webkit-scrollbar-track {
  background-color: #f2f3f5;
  margin: 0;
}

.notif-feed__wrapper::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 4.8px;
}

.notif-feed__wrapper::-webkit-resizer {
  background-repeat: no-repeat;
  width: 3px;
  height: 0;
}

.notif-feed__item {
  border-bottom: 1px solid #cdd0d6;
}

.notif-feed__empty {
  background-color: #f6f4fa;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}
