.cell-mass-actions__btn {
  cursor: pointer;
  width: 26px;
  height: 53px;
  position: relative;
}

.cell-mass-actions__btn:hover .cell-mass-actions__btn-ico {
  transform: translate(-50%, -50%)scale(1.2);
}

.cell-mass-actions__btn-count {
  color: #5229a1;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.cell-mass-actions__btn-ico {
  color: #303133;
  transition: all .2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cell-mass-actions__btn-ico .ico {
  width: 14px;
  height: 14px;
}

.cell-mass-actions__popover {
  --el-popover-padding: 10px;
}

.cell-mass-actions__popover.el-popover {
  min-width: auto;
}

.cell-mass-actions__list {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.cell-mass-actions__item {
  text-align: right;
  color: #5229a1;
  font-size: 12px;
}

.cell-mass-actions__item .span {
  cursor: pointer;
  transition: all .2s;
}

.cell-mass-actions__item .span:hover {
  opacity: .8;
  text-decoration: underline;
}
