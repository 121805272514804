.map-design-marker-2 {
  --rotate-marker: 0deg;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%) rotate(var(--rotate-marker));
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.map-design-marker-2__type {
  z-index: 10;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: relative;
}

.map-design-marker-2__err {
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(calc(-1 * var(--rotate-marker)));
  color: #fff;
  background-color: red;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.map-design-marker-2__in-selected, .map-design-marker-2__in-target {
  box-sizing: content-box;
  background-color: #fff;
  border: 2px dotted #ff008c;
  border-radius: 50%;
  width: calc(100% + 7px);
  height: calc(100% + 7px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -50%);
}

.--size-small .map-design-marker-2__in-selected, .--size-small .map-design-marker-2__in-target {
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  transform: translate(-50%, -51%);
}

.--size-large .map-design-marker-2__in-selected, .--size-large .map-design-marker-2__in-target {
  border-width: 3px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  transform: translate(-50%, -50%);
}

.map-design-marker-2__in-selected {
  border: 2px solid #00b2ff;
}

.map-design-marker-2__in-target-selected {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map-design-marker-2__in-target-selected:after {
  content: "";
  z-index: 1;
  box-sizing: content-box;
  background-color: #fff;
  border: 2px solid #00b2ff;
  border-radius: 50%;
  width: calc(100% + 18px);
  height: calc(100% + 18px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.--size-small .map-design-marker-2__in-target-selected:after {
  width: calc(100% + 16px);
  height: calc(100% + 16px);
}

.map-design-marker-2__in-target-selected:before {
  content: "";
  z-index: 2;
  box-sizing: content-box;
  background-color: #fff;
  border: 2px dotted #ff008c;
  border-radius: 50%;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.--size-small .map-design-marker-2__in-target-selected:before {
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}

.map-design-marker-2__ref-popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.map-design-marker-2__popover.el-popover {
  padding: 0;
}

.map-design-marker-2.--size-large {
  width: 47px;
  height: 47px;
}

.map-design-marker-2.--size-small {
  width: 30px;
  height: 30px;
}
