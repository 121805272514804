.employ-info-digital-v2 {
  padding-bottom: 18px;
  position: relative;
}

.employ-info-digital-v2__two {
  margin-top: 8px;
}

.employ-info-digital-v2__month-list {
  display: flex;
}

.employ-info-digital-v2__month-item {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #909399;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: calc(8.33333% - 2px);
  height: 19px;
  margin: 0 1px;
  padding-bottom: 3px;
  font-size: 12px;
  line-height: 1;
  transition: all .2s;
  display: flex;
}

.employ-info-digital-v2__month-item:hover {
  background-color: #5229a10d;
}

.--is-dbl-mode .employ-info-digital-v2__month-item {
  width: calc(16.6667% - 2px);
}

.employ-info-digital-v2__month-item.type-added {
  background-color: #d6e9ff;
  border: 2px solid #0000;
}

.employ-info-digital-v2__month-item.type-added:hover {
  background-color: #5229a10d;
}

.employ-info-digital-v2__month-item.type-select {
  color: #00b2ff;
  border: 2px solid #00b2ff;
}

.employ-info-digital-v2__surface-month-list {
  align-items: stretch;
  margin-top: 5px;
  display: flex;
}

.--is-dbl-mode .employ-info-digital-v2__surface-month-list {
  margin-top: 2px;
}

.employ-info-digital-v2__surface-month-item {
  width: 8.33333%;
  position: relative;
}

.employ-info-digital-v2__surface-month-item:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.--is-dbl-mode .employ-info-digital-v2__surface-month-item {
  width: 16.6667%;
  position: relative;
}

.--is-dbl-mode .employ-info-digital-v2__surface-month-item:before {
  content: "";
  padding-top: 66.6667%;
  display: block;
}

.employ-info-digital-v2__surface-month-item .surface-month-item {
  position: absolute;
  top: 0;
  left: 0;
}

.employ-info-digital-v2__period {
  color: #909399;
  width: 100%;
  height: 11px;
  font-size: 9px;
  line-height: 1;
  position: relative;
}

.employ-info-digital-v2__period-line {
  border: 1px solid #909399;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 40%;
  height: calc(100% - 1px);
  position: absolute;
  top: 1px;
  left: 40px;
}

.employ-info-digital-v2__period-line.left-line-one-edge {
  border-right: none;
  border-bottom-right-radius: 0;
}

.employ-info-digital-v2__period-line.right-line-one-edge {
  border-left: none;
  border-bottom-left-radius: 0;
}

.employ-info-digital-v2__period-from {
  position: absolute;
  top: 0;
  right: calc(100% + 4px);
}

.employ-info-digital-v2__period-to {
  position: absolute;
  top: 0;
  left: calc(100% + 4px);
}
