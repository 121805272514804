.cell-count-queue__edit {
  cursor: pointer;
  color: #c0c4cc;
  transition: all .2s;
  position: absolute;
  top: 8px;
  right: 8px;
}

.cell-count-queue__edit:hover {
  color: #5229a1;
}

.cell-count-queue__edit .ico {
  width: 14px;
  height: 14px;
}
