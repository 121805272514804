.btn-action {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  --border-color: transparent;
  --border-color--disabled: #c0c4cc;
  --color-icon: #5229a1;
  --color-icon--active: #fff;
  --color-icon--disabled: #c0c4cc;
  --bg-color: #fff;
  --bg-color--hover: #f6f4fa;
  --bg-color--active: #5229a1;
  --bg-color-active: #5229a1;
  --bg-color-active--hover: #633eaa;
  --bg-color-active--active: #422181;
  --bg-color--disabled: #fff;
  border: 1px solid var(--border-color);
  width: 36px;
  height: 36px;
  color: var(--color-icon);
  background-color: var(--bg-color);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: all .1s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 6px #0000001f;
}

.btn-action .ico {
  stroke: currentColor;
  fill: currentColor;
  width: 16px;
  height: 17px;
}

.btn-action:hover {
  background-color: var(--bg-color--hover);
}

.btn-action:active {
  background-color: var(--bg-color--active);
  color: var(--color-icon--active);
}

.btn-action--active {
  background-color: var(--bg-color-active);
  color: var(--color-icon--active);
}

.btn-action--active:hover {
  background-color: var(--bg-color-active--hover);
}

.btn-action--active:active {
  background-color: var(--bg-color-active--active);
}

.btn-action--disabled, .btn-action--disabled:hover, .btn-action--disabled:active {
  cursor: not-allowed;
  background-color: var(--bg-color--disabled);
  border-color: var(--border-color--disabled);
  color: var(--color-icon--disabled);
}

.btn-action__overlay-loading {
  background-color: #30313333;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-action__spinner {
  border-radius: 50%;
  width: 70%;
  height: 70%;
  animation: 1s linear infinite rotate;
  position: relative;
}

.btn-action__spinner:before {
  content: "";
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: 2s linear infinite prixClipFix;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
