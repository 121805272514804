.mass-digital-table {
  position: relative;
}

.mass-digital-table__container {
  --el-fill-color-lighter: #f5f7fa;
  --el-table-row-hover-bg-color: #ebeef5;
  --el-table-fixed-left-column: inset 10px 0 10px -10px #00000026;
}

.mass-digital-table__container .el-table__cell {
  padding: 8px 0;
}

.mass-digital-table__container .el-table__cell .cell {
  padding: 0 8px;
}

.mass-digital-table__container .el-table__cell.mass-digital-table__check .cell {
  padding-right: 0;
}

.mass-digital-table__container td.el-table__cell {
  border-bottom: none;
}

.mass-digital-table__head-cell {
  text-align: left;
}

.mass-digital-table__cell {
  color: #303133;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.mass-digital-table__cell.cell-gid {
  color: #5229a1;
  width: max-content;
  font-weight: 700;
}

.mass-digital-table__cell.cell-gid-partner {
  color: #606266;
  width: max-content;
}

.mass-digital-table__cell.cell-type-construct {
  color: #5229a1;
}

.mass-digital-table__cell.cell-type-construct .ico {
  width: 18px;
  height: 18px;
}

.mass-digital-table__cell.cell-remove {
  justify-content: end;
}

.mass-digital-table__label {
  color: #5229a1;
  border: 1px solid #5229a1;
  border-radius: 2px;
  width: max-content;
  padding: 0 6px;
  font-size: 9px;
  line-height: 16px;
}

.mass-digital-table__btn-remove {
  cursor: pointer;
  color: #909399;
  width: 16px;
  height: 16px;
  transition: all .2s;
}

.mass-digital-table__btn-remove:hover {
  color: #606266;
}

.mass-digital-table__btn-remove .ico {
  width: 16px;
  height: 16px;
}
