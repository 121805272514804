.head-user-info {
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  transition: background-color .1s;
  display: flex;
}

.head-user-info:hover {
  background-color: #5229a10d;
}
