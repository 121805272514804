.oper-mass-employ-popup .el-dialog__header {
  padding: 16px 20px 16px 16px;
}

.oper-mass-employ-popup .el-dialog__headerbtn {
  top: 3px;
}

.oper-mass-employ-popup .el-dialog__title {
  font-weight: 500;
}

.oper-mass-employ-popup .el-dialog__body {
  padding: 16px;
}

.oper-mass-employ-popup .el-dialog__close {
  --el-color-primary: #5229a1;
}

.oper-mass-employ-popup__action-buttons {
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
  display: flex;
}

.oper-mass-employ-popup__action-buttons .bs-btn {
  --el-button-font-weight: 400;
  border-radius: 2px;
  margin: 0;
  font-size: 14px;
}

.oper-mass-employ-popup__content {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.oper-mass-employ-popup__action-buttons {
  margin-top: 16px;
}
