.table-grid-td {
  min-height: 40px;
  padding: var(--td-padding);
  position: relative;
}

.table-grid-td.td-col-check {
  z-index: 80;
  background-color: #fff;
  padding: 16px 12px;
  position: sticky;
  left: 0;
}

.table-grid-td.td-col-check .el-checkbox {
  height: -moz-fit-content;
  height: fit-content;
}

.table-grid-td.td-col-check .el-checkbox__label {
  display: none;
}

.table-grid-td.td-col-check .el-checkbox__inner {
  outline: 0 !important;
}

.table-grid-td.td-fixed-left, .table-grid-td.td-fixed-right {
  z-index: 80;
  background-color: #fff;
  position: sticky;
}

.table-grid-td.td-fixed-left:before, .table-grid-td.td-fixed-right:before {
  content: "";
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.table-grid-td.td-fixed-left:before {
  border-right: 1px solid #ebeef5;
  right: -1px;
}

.table-grid-td.td-fixed-left.td-fixed-left-shadow:before {
  box-shadow: inset 10px 0 10px -10px #00000026;
}

.table-grid-td.td-fixed-right:before {
  border-left: 1px solid #ebeef5;
  left: -1px;
}
