.all-operator-table {
  width: 100%;
  height: 100%;
  position: relative;
}

.all-operator-table__container {
  z-index: 1;
  --el-fill-color-lighter: #f5f7fa;
  --el-table-header-bg-color: #f5f7fa;
  --el-table-row-hover-bg-color: #ebeef5;
  --el-table-fixed-left-column: inset 10px 0 10px -10px #00000026;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.all-operator-table__container .el-table__cell {
  padding: 13px 0;
}

.all-operator-table__head-cell {
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
}

.all-operator-table__head-cell .icon-bell, .all-operator-table__head-cell .icon-more-dots, .all-operator-table__head-cell .icon-bell .ico, .all-operator-table__head-cell .icon-more-dots .ico {
  width: 16px;
  height: 16px;
}

.all-operator-table__cell-padx-16 .cell {
  padding: 0 16px;
}

.all-operator-table__cell {
  color: #303133;
}
