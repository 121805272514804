.marker-type-angular {
  --color-status-1: transparent;
  --color-status-2: transparent;
  --color-crown-1: transparent;
  --color-crown-2: transparent;
  --color-side-1: #333;
  --color-side-2: #333;
  --rotate-label: 0deg;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -47%)rotate(16deg);
}

.marker-type-angular__icon {
  width: 115%;
  height: 115%;
  position: absolute;
  top: 43%;
  left: 47%;
  transform: translate(-50%, -50%);
}

.marker-type-angular__icon .svg-icon, .marker-type-angular__icon .ico {
  width: 100%;
  height: 100%;
}

.marker-type-angular__crown {
  opacity: .6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-angular__crown-item {
  --color-crown: #67c23a;
  width: 150%;
  height: 150%;
  position: absolute;
}

.marker-type-angular__crown-item .svg-icon, .marker-type-angular__crown-item .ico {
  width: 100%;
  height: 100%;
}

.marker-type-angular__crown-item.crown-item-1 {
  --color-crown: var(--color-crown-1);
  top: 30%;
  left: -21%;
  transform: translate(-50%, -50%)rotate(-3deg);
}

.marker-type-angular__crown-item.crown-item-2 {
  --color-crown: var(--color-crown-2);
  top: 30%;
  left: 117%;
  transform: translate(-50%, -50%)rotate(148deg);
}

.marker-type-angular__label {
  color: #fff;
  transform: translate(-50%, -50%) rotate(calc(var(--rotate-label)  - 16deg));
  font-size: 8px;
  line-height: 1;
  position: absolute;
}

.--size-large .marker-type-angular__label {
  font-size: 10px;
}

.--size-small .marker-type-angular__label {
  font-size: 7px;
}

.marker-type-angular__label.label-1 {
  top: 40%;
  left: 20%;
}

.marker-type-angular__label.label-2 {
  top: 40%;
  right: 11%;
}
