.grid-designs-info {
  text-align: left;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  min-width: -moz-fit-content;
  min-width: fit-content;
  line-height: 22px;
  display: flex;
}

.grid-designs-info__block {
  flex-direction: column;
  display: flex;
}

.grid-designs-info__block.gap-8 {
  gap: 8px;
}

.grid-designs-info__block.gap-4 {
  gap: 4px;
}

.grid-designs-info__group {
  flex-direction: column;
  display: flex;
}

.grid-designs-info__group.pb-8 {
  padding-bottom: 8px;
}

.grid-designs-info__group.group-row {
  flex-direction: row;
  align-items: center;
}

.grid-designs-info__group.group-row.gap-48 {
  gap: 48px;
}

.grid-designs-info__group.group-row.gap-32 {
  gap: 32px;
}

.grid-designs-info__group.group-row.gap-16 {
  gap: 16px;
}

.grid-designs-info__group.group-row.gap-8 {
  gap: 8px;
}

.grid-designs-info__group.group-row.row-space-between {
  justify-content: space-between;
}

.grid-designs-info__group.group-row.align-start {
  align-items: flex-start;
}

.grid-designs-info__group-label {
  color: #909399;
  font-size: 10px;
  line-height: 16px;
}

.grid-designs-info__group-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90px;
  font-size: 12px;
  overflow: hidden;
}

.grid-designs-info__head {
  cursor: pointer;
  align-items: center;
  gap: 4px;
  transition: all .2s;
  display: flex;
}

.grid-designs-info__head:hover {
  opacity: .8;
  text-decoration: underline;
}

.grid-designs-info__head .head-add-tprog-icon {
  color: #5229a1;
  margin-right: 4px;
}

.grid-designs-info__head .head-add-tprog-icon .ico {
  width: 12px;
  height: 12px;
}

.grid-designs-info__head .head-gid {
  color: #5229a1;
  text-transform: uppercase;
  font-weight: 700;
}

.grid-designs-info__head .head-inner-id {
  color: #606266;
  font-size: 12px;
}

.grid-designs-info--is-compact .grid-designs-info__head {
  position: relative;
}

.grid-designs-info--is-compact .grid-designs-info__head .head-inner-id {
  min-width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-65%);
}

.grid-designs-info--is-compact .grid-designs-info__head .head-gid {
  font-size: 12px;
}

.grid-designs-info__side-select .side-select {
  gap: 4px;
}

.grid-designs-info__side-select .side-select__item {
  --el-button-size: 18px;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  font-size: 10px;
  line-height: 1;
  display: flex;
}

.grid-designs-info__side-select .side-select__item .ico {
  width: 7px;
  height: 11px;
}

.grid-designs-info__side-select .side-select__item span {
  gap: 2px;
}

.grid-designs-info__side-select .side-select__item.--is-light {
  padding: 2px 4px;
}

.grid-designs-info__side-select .side-select__digital-badge {
  width: 12px;
  height: 12px;
  top: -5px;
  left: 94%;
}

.grid-designs-info__side-select .side-select__digital-badge .ico {
  width: 12px;
  height: 12px;
}

.grid-designs-info__btn-actions {
  align-items: center;
  gap: 8px;
  display: flex;
}

.grid-designs-info__btn-actions .btn-action {
  width: 28px;
  height: 23px;
}

.grid-designs-info__btn-actions .btn-action .ico {
  width: 13px;
  height: 13px;
}

.grid-designs-info__btn-open {
  cursor: pointer;
  color: #5229a1;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  transition: all .2s;
  display: flex;
  box-shadow: 0 0 6px #0000001f;
}

.grid-designs-info__btn-open .ico {
  width: 14px;
  height: 14px;
  transition: all .2s;
}

.grid-designs-info__btn-open:hover {
  box-shadow: 0 0 12px #ce3a3a1f;
}

.grid-designs-info--open .grid-designs-info__btn-open .ico {
  transform: rotate(180deg);
}

.grid-designs-info__btn-actions-item {
  cursor: pointer;
  color: #5229a1;
  width: 13px;
  height: 13px;
  transition: all .1s;
}

.grid-designs-info__btn-actions-item .ico {
  width: 13px;
  height: 13px;
}

.grid-designs-info__btn-actions-item:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
  transform: scale(1.2);
}

.grid-designs-info__address {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5229a1;
  max-width: 400px;
  overflow: hidden;
}

.grid-designs-info__service-status {
  color: red;
}

.grid-designs-info__type {
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  display: flex;
}

.grid-designs-info__type .grid-designs-info__group {
  gap: 8px;
}

.grid-designs-info--is-compact .grid-designs-info__type {
  font-size: 12px;
}

.grid-designs-info__type-construct, .grid-designs-info__type-size {
  color: #303133;
}

.grid-designs-info__type-mech {
  color: #5229a1;
  border: 1px solid #5229a1;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 17px;
  padding: 0 5px;
  font-size: 10px;
  display: flex;
}

.grid-designs-info__type-construct-icon {
  color: #5229a1;
}

.grid-designs-info__type-construct-icon .ico {
  width: 16px;
  height: 16px;
}

.grid-designs-info .a {
  color: #5229a1;
  text-decoration: underline;
}

.grid-designs-info .a:hover {
  opacity: .8;
}
