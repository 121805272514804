.auth-layout {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.auth-layout__container {
  height: calc(100% - 48px);
}
