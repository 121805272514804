.notification-center-view {
  height: 100%;
  padding: 16px 16px 16px 0;
  position: relative;
}

.notification-center-view__loader {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.notification-center-view__container {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
}

.notification-center-view__action-panel {
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 100%;
  display: flex;
  position: relative;
}

.notification-center-view__action-panel-group {
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.notification-center-view__pagination {
  justify-content: flex-end;
  display: flex;
}

.notification-center-view__table {
  background-color: #fff;
  border-radius: 4px;
  flex-grow: 1;
  padding: 16px;
  position: relative;
}
