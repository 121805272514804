.upload-popup .el-dialog__header {
  padding: 16px 20px 16px 16px;
}

.upload-popup .el-dialog__headerbtn {
  top: 3px;
}

.upload-popup .el-dialog__title {
  font-weight: 500;
}

.upload-popup .el-dialog__body {
  padding: 16px;
}

.upload-popup .el-dialog__close {
  --el-color-primary: #5229a1;
}

.upload-popup__action-buttons {
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
  display: flex;
}

.upload-popup__action-buttons .bs-btn {
  --el-button-font-weight: 400;
  border-radius: 2px;
  margin: 0;
  font-size: 14px;
}

.upload-popup .el-dialog__body {
  padding-bottom: 0;
}

.upload-popup__content {
  flex-direction: column;
  align-items: center;
  padding: 24px 70px;
  display: flex;
}

.upload-popup__drag-zone {
  background-color: #ecf5ff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
}

.upload-popup__drag-zone.--is-draggable {
  border-style: dashed;
  border-color: #d9ecff;
}

.upload-popup__drag-zone.--is-draggable .upload-popup__icon {
  animation: .8s ease-in-out infinite both ping;
}

.upload-popup__input {
  z-index: 10;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-popup__icon {
  color: #a0cfff;
}

.upload-popup__icon .ico {
  width: 64px;
  height: 64px;
}

.upload-popup__info {
  margin-top: 16px;
}

.upload-popup__info .a {
  cursor: pointer;
  color: #5229a1;
  text-decoration: underline;
  transition: all .2s;
}

.upload-popup__info .a:hover {
  opacity: .7;
}

.upload-popup__btn-action {
  margin-top: 32px;
}

@keyframes ping {
  0% {
    opacity: .8;
    transform: scale(.2);
  }

  80% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 0;
    transform: scale(2.2);
  }
}
