.bs-checkbox {
  --el-checkbox-checked-text-color: #5229a1;
  --el-checkbox-checked-bg-color: #5229a1;
  --el-checkbox-checked-input-border-color: #5229a1;
  margin-right: 0;
}

.bs-checkbox:hover .el-checkbox__inner {
  border-color: #5229a1;
}
