.table-grid {
  --th-font-size: 14px;
  --td-font-size: 14px;
  --th-text-align: center;
  --td-text-align: center;
  --th-vertical-align: middle;
  --td-vertical-align: middle;
  --th-padding: 2px 5px;
  --td-padding: 3px 5px;
  --th-cell-font-color: #909399;
  --th-bg-color: #f5f7fa;
  --ft-bg-color: #f5f7fa;
  border: 1px solid #ebeef5;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.table-grid::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-grid::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 4px;
  height: 0;
}

.table-grid::-webkit-scrollbar-track {
  background-color: #f2f3f5;
  margin: 0;
}

.table-grid::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 4.8px;
}

.table-grid::-webkit-resizer {
  background-repeat: no-repeat;
  width: 3px;
  height: 0;
}

.table-grid__table {
  border-collapse: collapse;
  width: 100%;
}

.table-grid__thead {
  z-index: 100;
  background-color: var(--th-bg-color);
  position: sticky;
  top: 0;
}

.table-grid__tfoot {
  z-index: 100;
  background-color: var(--ft-bg-color);
  position: sticky;
  bottom: 0;
}

.table-grid__th, .table-grid__td {
  border: 1px solid #ebeef5;
  border-top-width: 0;
}

.table-grid__th:first-of-type, .table-grid__td:first-of-type {
  border-left-width: 0;
}

.table-grid__th:last-of-type, .table-grid__td:last-of-type {
  border-right-width: 0;
}

.table-grid__th {
  vertical-align: var(--th-vertical-align);
  text-align: var(--th-text-align);
  font-size: var(--th-font-size);
}

.table-grid__td {
  vertical-align: var(--td-vertical-align);
  text-align: var(--td-text-align);
  font-size: var(--td-font-size);
}

.table-grid__cell {
  width: 100%;
  height: 100%;
}

.table-grid__tr-loader {
  height: 50px;
}

.table-grid__loader-spinner {
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
  position: absolute;
  left: 0;
}

.table-grid__loader-spinner .loader-spinner {
  flex-direction: row;
  gap: 8px;
}

.table-grid__loader-spinner .loader-spinner__spinner {
  width: 30px;
  height: 30px;
}

.table-grid__loader-spinner .loader-spinner__spinner:before {
  border-width: 3px;
}

.table-grid__loader-spinner .loader-spinner__title {
  font-size: var(--td-font-size);
  color: #606266;
  margin-top: 0;
}
