.bs-radio {
  margin-right: 24px;
}

.bs-radio .el-radio__inner:after {
  width: 6px;
  height: 6px;
}

.bs-radio .el-radio__label {
  font-weight: 400;
}
