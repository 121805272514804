.bs-header {
  --el-header-height: 48px;
  --el-header-padding: 0 16px;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ed;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  display: flex;
  box-shadow: 0 0 12px #ce3a3a1f;
}

.bs-header__left {
  align-items: center;
  gap: 32px;
  display: flex;
}

.bs-header__breadcrumb {
  max-width: 450px;
}

.bs-header__breadcrumb .el-breadcrumb {
  line-height: 1.2;
}

.bs-header__breadcrumb .el-breadcrumb__inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 350px;
  overflow: hidden;
}

.bs-header__head-title {
  text-align: center;
  color: #5229a1;
  max-width: 600px;
  line-height: 1;
}

.bs-header__right {
  align-items: center;
  display: flex;
}

.bs-header__notif, .bs-header__user-info {
  padding: 0 4px;
}

.bs-header__dropdown-menu-item {
  gap: 12px;
}

.bs-header__dropdown-menu-item .ico {
  width: 18px;
  height: 18px;
}
