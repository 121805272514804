.marker-type-two {
  --color-status-1: transparent;
  --color-status-2: transparent;
  --color-status-3: transparent;
  --color-crown-1: transparent;
  --color-crown-2: transparent;
  --color-crown-3: transparent;
  --color-side-1: #333;
  --color-side-2: #333;
  --color-side-3: #333;
  --rotate-label: 0deg;
  width: 100%;
  height: 100%;
  position: relative;
  transform: rotate(30deg);
}

.marker-type-two__icon {
  width: 115%;
  height: 115%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-two__icon .svg-icon, .marker-type-two__icon .ico {
  width: 100%;
  height: 100%;
}

.marker-type-two__crown {
  opacity: .6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-two__crown-item {
  --color-crown: #67c23a;
  width: 110%;
  height: 110%;
  position: absolute;
}

.marker-type-two__crown-item .svg-icon, .marker-type-two__crown-item .ico {
  width: 100%;
  height: 100%;
}

.marker-type-two__crown-item.crown-item-1 {
  --color-crown: var(--color-crown-1);
  top: 10%;
  left: 122%;
  transform: translate(-50%, -50%)rotate(60deg);
}

.marker-type-two__crown-item.crown-item-2 {
  --color-crown: var(--color-crown-2);
  top: 10%;
  left: -21%;
  transform: translate(-50%, -50%)rotate(-60deg);
}

.marker-type-two__crown-item.crown-item-3 {
  --color-crown: var(--color-crown-3);
  top: 132%;
  left: 51%;
  transform: translate(-50%, -50%)rotate(180deg);
}

.marker-type-two__label {
  color: #fff;
  transform: translate(-50%, -50%) rotate(calc(var(--rotate-label)  - 30deg));
  font-size: 8px;
  line-height: 1;
  position: absolute;
}

.--size-large .marker-type-two__label {
  font-size: 10px;
}

.--size-small .marker-type-two__label {
  font-size: 7px;
}

.marker-type-two__label.label-1 {
  top: 37%;
  right: 14%;
}

.--size-small .marker-type-two__label.label-1 {
  right: 13%;
}

.marker-type-two__label.label-2 {
  top: 37%;
  left: 31%;
}

.--size-small .marker-type-two__label.label-2 {
  left: 30%;
}

.marker-type-two__label.label-3 {
  bottom: 1%;
  left: 51%;
}
