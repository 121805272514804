.operators-view {
  height: 100%;
  padding: 16px 16px 16px 0;
  position: relative;
}

.operators-view__loader {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.operators-view__container {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
}

.operators-view__action-panel {
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 100%;
  display: flex;
  position: relative;
}

.operators-view__action-panel-group {
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.operators-view__pagination {
  justify-content: flex-end;
  display: flex;
}

.operators-view__table {
  background-color: #fff;
  border-radius: 4px;
  flex-grow: 1;
  padding: 16px;
  position: relative;
}

.operators-view__action-panel {
  gap: 16px;
  position: relative;
}

.operators-view__action-item.item-import-digital .bs-btn {
  height: 36px;
}

.operators-view__action-item.item-import-static .bs-btn {
  --el-button-text-color: #fff;
  --el-button-bg-color: #67c23a;
  --el-button-border-color: #67c23a;
  --el-button-hover-text-color: #fff;
  --el-button-hover-bg-color: #85cf60;
  --el-button-hover-border-color: #85cf60;
  --el-button-active-text-color: #fff;
  --el-button-active-bg-color: #5fa93a;
  --el-button-active-border-color: #5fa93a;
  height: 36px;
}

.operators-view__action-item.item-command-panel {
  position: absolute;
  top: 0;
  right: 0;
}
