.nt-cell-message__bg {
  z-index: 0;
  background-color: #5229a10d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nt-cell-message__text {
  z-index: 1;
  text-align: left;
  position: relative;
}
