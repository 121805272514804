.main-head-logo {
  align-items: center;
  gap: 7px;
  display: flex;
  position: relative;
}

.main-head-logo__title {
  text-transform: uppercase;
  color: #5229a1;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}

.main-head-logo__img {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  width: 39px;
  height: 24px;
  display: block;
}
