.side-queue-item {
  align-items: center;
  gap: 8px;
  font-size: 12px;
  display: flex;
}

.side-queue-item__date {
  color: #000;
}

.side-queue-item__index, .side-queue-item__target-id {
  color: #5229a1;
  font-size: 14px;
  font-weight: 700;
}

.side-queue-item__status {
  flex-direction: row;
  gap: 8px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.side-queue-item__status .el-tag {
  background-color: #fff;
  border: 1px solid;
  min-width: 100px;
  height: 20px;
  padding: 0 8px;
}

.side-queue-item__status .primary {
  color: #5229a1;
}

.side-queue-item__status .danger {
  color: #b90000;
}

.side-queue-item__status .warning {
  color: #ffc473;
}

.side-queue-item__status .success {
  color: #67c23a;
}

.side-queue-item__target-name, .side-queue-item__user-name {
  color: #000;
}

.side-queue-item__telegram, .side-queue-item__email, .side-queue-item__phone {
  cursor: pointer;
  color: #c0c4cc;
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  transition: all .1s;
}

.side-queue-item__telegram .ico, .side-queue-item__email .ico, .side-queue-item__phone .ico {
  width: 16px;
  height: 16px;
}

.side-queue-item__telegram:hover, .side-queue-item__email:hover, .side-queue-item__phone:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
  transform: scale(1.2);
}
