.notif-system-info {
  --color-icon: #5229a1;
  --color-label: #5229a1;
  align-items: center;
  gap: 8px;
  display: flex;
}

.notif-system-info__icon {
  background-color: var(--color-icon);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.notif-system-info__icon .ico {
  color: #fff;
  width: 20px;
  height: 20px;
}

.notif-system-info__label {
  color: var(--color-label);
}
