.edit-oper-queue-popup {
  width: auto;
  min-width: 500px;
}

.edit-oper-queue-popup .el-dialog__header {
  padding: 16px 20px 16px 16px;
}

.edit-oper-queue-popup .el-dialog__headerbtn {
  top: 3px;
}

.edit-oper-queue-popup .el-dialog__title {
  font-weight: 500;
}

.edit-oper-queue-popup .el-dialog__body {
  padding: 16px;
}

.edit-oper-queue-popup .el-dialog__close {
  --el-color-primary: #5229a1;
}

.edit-oper-queue-popup__action-buttons {
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
  display: flex;
}

.edit-oper-queue-popup__action-buttons .bs-btn {
  --el-button-font-weight: 400;
  border-radius: 2px;
  margin: 0;
  font-size: 14px;
}

.edit-oper-queue-popup__content {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.edit-oper-queue-popup__select {
  width: 180px;
}

.edit-oper-queue-popup__select .el-select__wrapper {
  min-height: 36px;
}
