.design-marker-popup {
  color: #303133;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  gap: 8px;
  min-width: 280px;
  max-width: 320px;
  padding: 8px;
  font-size: 12px;
  display: flex;
}

.design-marker-popup__group {
  flex-wrap: wrap;
  gap: 8px 16px;
  display: flex;
}

.design-marker-popup__group.align-center {
  align-items: center;
}

.design-marker-popup__group.justify-space-between {
  justify-content: space-between;
}

.design-marker-popup__group .control {
  align-items: baseline;
  gap: 4px;
  display: flex;
}

.design-marker-popup__group .control__label {
  color: #909399;
  padding: 0;
  font-size: 12px;
}

.design-marker-popup__group .control__value {
  color: #303133;
  font-size: 12px;
  font-weight: 400;
}

.design-marker-popup__photo {
  width: 100%;
  height: 100px;
}

.design-marker-popup__photo .el-image {
  width: 100%;
  height: 100%;
}

.design-marker-popup__address {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.design-marker-popup__gid {
  color: #5229a1;
  min-width: max-content;
  font-weight: 700;
}

.design-marker-popup__type {
  align-items: baseline;
  gap: 8px;
  display: flex;
}

.design-marker-popup__type .ico {
  color: #5229a1;
  width: 12px;
  height: 12px;
}

.design-marker-popup__mech {
  color: #5229a1;
  border: 1px solid #5229a1;
  border-radius: 2px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 6px;
  font-size: 10px;
  line-height: 13px;
}

.design-marker-popup__side-select .side-select {
  gap: 8px;
}

.design-marker-popup__side-select .side-select__digital-badge {
  top: -8px;
}

.design-marker-popup__actions-btn {
  gap: 8px;
  display: flex;
}

.design-marker-popup__actions-btn .bs-btn {
  flex: 1;
  height: 32px;
  margin: 0;
}

.design-marker-popup__actions-btn .btn-action {
  width: 32px;
  height: 32px;
  box-shadow: none;
  border-color: #5229a1;
}
