.edit-queue-item {
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  display: flex;
}

.edit-queue-item__check {
  justify-content: center;
  align-items: center;
  display: flex;
}

.edit-queue-item__check .el-checkbox {
  height: auto;
}

.edit-queue-item__check .el-checkbox__label {
  display: none;
}

.edit-queue-item__drop {
  cursor: move;
  color: #c0c4cc;
  transition: all .2s;
}

.edit-queue-item__drop:hover {
  color: #606266;
}

.edit-queue-item__drop .ico {
  width: 16px;
  height: 16px;
}

.edit-queue-item__info {
  color: #303133;
  flex: 1;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  display: flex;
}

.edit-queue-item__position, .edit-queue-item__number {
  color: #5229a1;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}

.edit-queue-item__contact {
  align-items: center;
  gap: 8px;
  display: flex;
}

.edit-queue-item__soc {
  cursor: pointer;
  color: #c0c4cc;
  width: 16px;
  height: 16px;
  transition: all .1s;
}

.edit-queue-item__soc .ico {
  width: 16px;
  height: 16px;
}

.edit-queue-item__soc:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
  transform: scale(1.2);
}

.edit-queue-item__user-card {
  cursor: pointer;
  color: #5229a1;
  transition: all .2s;
}

.edit-queue-item__user-card:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
  transform: scale(1.1);
}

.edit-queue-item__user-card .ico {
  width: 16px;
  height: 16px;
}

.edit-queue-item__remove {
  cursor: pointer;
  color: red;
  transition: all .2s;
}

.edit-queue-item__remove:hover {
  color: #ff0000b3;
  transform: scale(1.05);
}

.edit-queue-item__remove:active {
  transform: scale(.98);
}

.edit-queue-item__remove .ico {
  width: 16px;
  height: 16px;
}
