.grid-pagination, .grid-pagination__count-page {
  align-items: center;
  gap: 16px;
  display: flex;
}

.grid-pagination__count-page-select {
  width: 70px;
}

.grid-pagination__count-page-select .el-select__wrapper {
  min-height: 26px;
}

.grid-pagination__count-page-select .el-select__selected-item {
  color: #5229a1;
}

.grid-pagination__paginator .el-pager {
  --el-pagination-button-color: #606266;
}

.grid-pagination__paginator .el-pagination {
  --el-pagination-button-color: #606266;
  --el-fill-color-blank: transparent;
}
