.footer-auth {
  justify-content: center;
  align-items: center;
  padding: 16px;
  line-height: normal;
  display: flex;
}

.footer-auth__wrap {
  align-items: center;
  gap: 24px;
  display: flex;
}

.footer-auth__link {
  color: #5229a1;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  transition: all .2s;
  display: flex;
}

.footer-auth__link:hover {
  opacity: .8;
}

.footer-auth__link-icon {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.footer-auth__link-icon .ico {
  width: 18px;
  height: 18px;
}

.footer-auth__link-label {
  text-decoration: underline;
}

.footer-auth__copy {
  text-transform: uppercase;
  color: #a8abb2;
  font-size: 13px;
}
