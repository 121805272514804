.bs-breadcrumb__item {
  --el-text-color-regular: #303133;
}

.bs-breadcrumb__item a {
  --el-text-color-primary: #909399;
  --el-color-primary: #5229a1;
  font-weight: 400;
}

.bs-breadcrumb__item .el-icon {
  margin: 0 4px;
}

.bs-breadcrumb__item .ico {
  fill: #909399;
  width: 16px;
  height: 16px;
  display: block;
}
