.operator-item-view {
  height: 100%;
  padding: 16px 16px 16px 0;
  position: relative;
}

.operator-item-view__loader {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.operator-item-view__container {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
}

.operator-item-view__action-panel {
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 100%;
  display: flex;
  position: relative;
}

.operator-item-view__action-panel-group {
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.operator-item-view__pagination {
  justify-content: flex-end;
  display: flex;
}

.operator-item-view__table {
  background-color: #fff;
  border-radius: 4px;
  flex-grow: 1;
  padding: 16px;
  position: relative;
}

.operator-item-view__action-panel {
  gap: 16px;
  position: relative;
}

.operator-item-view__action-item.item-filter-aside .el-badge {
  --el-badge-size: 16px;
  --el-badge-padding: 4px;
  --el-badge-font-size: 10px;
}

.operator-item-view__action-item.item-filter-aside .el-badge__content {
  background-color: red;
  min-width: 16px;
  font-weight: 700;
}

.operator-item-view__action-item.item-command-panel {
  position: absolute;
  top: 0;
  right: 0;
}

.operator-item-view__action-item .bs-btn {
  height: 36px;
}
