.notif-message-system {
  flex-direction: column;
  gap: 8px;
  padding: 8px 8px 16px;
  display: flex;
}

.notif-message-system.--is-message-not-read {
  background-color: #5229a10d;
}

.notif-message-system__head {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

.notif-message-system__date {
  color: #606266;
  font-size: 11px;
}

.notif-message-system__message {
  color: #303133;
}

.notif-message-system__btn-actions {
  gap: 8px;
  display: flex;
}

.notif-message-system__btn-actions .bs-btn {
  border-radius: 2px;
  height: 24px;
  margin: 0;
  padding: 0 12px;
}
