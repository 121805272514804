.employ-datepicker {
  background-color: #fff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
}

.employ-datepicker__main .air-datepicker {
  --adp-font-family: Exo 2, sans-serif;
  --adp-font-size: 14px;
  --adp-color: #303133;
  --adp-color-other-month: #c0c4cc;
  --adp-cell-background-color-selected: #5229a180;
  --adp-cell-background-color-selected-hover: #5229a199;
  --adp-border-color-inline: transparent;
  --adp-cell-border-radius: 50%;
  --adp-day-cell-height: 27px;
  --adp-width: 220px;
}

.employ-datepicker__main .air-datepicker--navigation {
  display: none;
}

.employ-datepicker__main .air-datepicker-body--cells.-days- {
  place-items: center;
}

.employ-datepicker__main .air-datepicker-cell {
  -webkit-user-select: none;
  user-select: none;
  color: #67c23a;
  width: 24px;
  height: 24px;
  font-size: 12px;
}

.employ-datepicker__main .air-datepicker-cell.-selected- {
  color: #fff;
  background-color: #67c23acc;
}

.employ-datepicker__main .air-datepicker-cell.-focus- {
  color: #fff;
  background-color: #67c23a;
}

.employ-datepicker__main .air-datepicker-cell.type-free.-disabled- {
  background-color: unset;
}

.employ-datepicker__main .air-datepicker-cell.type-disabled {
  color: #e4e7ed;
}

.employ-datepicker__main .air-datepicker-cell.type-disabled.-selected- {
  color: #fff;
  background-color: #c0c4cccc;
}

.employ-datepicker__main .air-datepicker-cell.type-disabled.-focus- {
  color: #fff;
  background-color: #c0c4cc;
}

.employ-datepicker__main .air-datepicker-cell.type-free-reserved, .employ-datepicker__main .air-datepicker-cell.type-reserved {
  color: #ffc473;
}

.employ-datepicker__main .air-datepicker-cell.type-free-reserved.-selected-, .employ-datepicker__main .air-datepicker-cell.type-reserved.-selected- {
  color: #fff;
  background-color: #ffc473cc;
}

.employ-datepicker__main .air-datepicker-cell.type-free-reserved.-focus-, .employ-datepicker__main .air-datepicker-cell.type-reserved.-focus- {
  color: #fff;
  background-color: #ffc473;
}

.employ-datepicker__main .air-datepicker-cell.type-mixed {
  color: #ff9e97;
}

.employ-datepicker__main .air-datepicker-cell.type-mixed.-selected- {
  color: #fff;
  background-color: #ff9e97cc;
}

.employ-datepicker__main .air-datepicker-cell.type-mixed.-focus- {
  color: #fff;
  background-color: #ff9e97;
}

.employ-datepicker__main .air-datepicker-cell.type-busy {
  color: #b90000;
}

.employ-datepicker__main .air-datepicker-cell.type-busy.-selected- {
  color: #fff;
  background-color: #b90000cc;
}

.employ-datepicker__main .air-datepicker-cell.type-busy.-focus- {
  color: #fff;
  background-color: #b90000;
}

.employ-datepicker__footer {
  padding: 8px 12px 10px;
}
