.employ-calendar {
  padding: 4px 0;
}

.employ-calendar__head-title {
  color: #909399;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 22px;
}

.employ-calendar__wrap {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.employ-calendar__week {
  gap: 4px;
  display: flex;
}

.employ-calendar__day {
  cursor: default;
  color: #fff;
  background-color: #67c23a;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 1;
  display: flex;
}

.employ-calendar__day.type-disable {
  background-color: #e4e7ed;
}

.employ-calendar__day.type-reserved {
  background-color: #ffc473;
}

.employ-calendar__day.type-busy {
  background-color: #b90000;
}
