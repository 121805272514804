.surface-month-item {
  background-color: #67c23a;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.surface-month-item.--is-hover .surface-month-item__value, .surface-month-item:hover .surface-month-item__value {
  opacity: 1;
}

.surface-month-item__value {
  color: #fff;
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  transition: all .2s;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--is-static .surface-month-item__value, .--is-single-mode .surface-month-item__value {
  bottom: unset;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

.surface-month-item.status-reserved {
  background-color: #ffc473;
}

.surface-month-item.status-busy {
  background-color: #b90000;
}

.surface-month-item.status-disabled {
  background-color: #c0c4cc;
}

.surface-month-item.status-partly {
  background-color: #ff9e97;
}

.surface-month-item.type-multi {
  background-color: #fff;
  flex-direction: column;
  gap: 2px;
  font-size: 12px;
  line-height: 1;
}

.surface-month-item__chunk {
  color: #fff;
  background-color: #67c23a;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding: 0 4px 2px;
  display: flex;
  position: relative;
}

.surface-month-item__chunk.chunk-type-reserved {
  color: #303133;
  background-color: #ffc473;
}

.surface-month-item__chunk .surface-month-item__value {
  top: 50%;
  bottom: unset;
  transform: translateY(-50%);
}

.surface-month-item__value-right {
  display: none;
}

.--is-dbl-mode .surface-month-item__value-right {
  display: block;
}

.--is-static .surface-month-item__value-right {
  display: none;
}
