.main-menu {
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 6px #0000001f;
}

.main-menu__btn-collapse {
  cursor: pointer;
  color: #000;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 58px;
  padding: 0 20px;
  transition: background-color .1s;
  display: flex;
}

.main-menu__btn-collapse:hover {
  background-color: #5229a10d;
}

.main-menu__btn-collapse .ico {
  fill: currentColor;
  width: 18px;
  height: 18px;
  display: block;
}

.main-menu__btn-collapse.--is-collapse .ico {
  transform: rotate(180deg);
}

.main-menu__menu {
  border-right: none;
}

.main-menu__menu:not(.el-menu--collapse) {
  width: 220px;
  max-width: 220px;
}

.main-menu__item {
  --el-menu-item-height: 58px;
  --el-menu-active-color: #5229a1;
  --el-menu-hover-bg-color: #5229a10d;
  gap: 8px;
}

.main-menu__item.is-active a {
  color: #5229a1;
}

.main-menu__item a {
  color: #303133;
}

.main-menu__item-title {
  color: #303133;
  font-weight: 500;
}

.el-menu--collapse .main-menu__item-title {
  opacity: 0;
}

.main-menu__item-icon .ico {
  width: 24px;
  height: 24px;
}
