.bs-header-auth {
  --el-header-height: 48px;
  --el-header-padding: 0 16px;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ed;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  display: flex;
  box-shadow: 0 0 12px #ce3a3a1f;
}

.bs-header-auth__left {
  align-items: center;
  gap: 32px;
  display: flex;
}
