.table-grid-th {
  padding: var(--th-padding);
  color: var(--th-cell-font-color);
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.table-grid-th:after {
  content: "";
  border-bottom: 1px solid #ebeef5;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.table-grid-th.th-col-green {
  background-color: #e1f3d8;
}

.table-grid-th.th-col-check {
  z-index: 90;
  background-color: var(--th-bg-color);
  padding: 16px 12px;
  position: sticky;
  left: 0;
}

.table-grid-th.th-col-check .el-checkbox {
  height: -moz-fit-content;
  height: fit-content;
}

.table-grid-th.th-col-check .el-checkbox__label {
  display: none;
}

.table-grid-th.th-col-check .el-checkbox__inner {
  outline: 0 !important;
}

.table-grid-th.th-fixed-left, .table-grid-th.th-fixed-right {
  z-index: 90;
  background-color: var(--th-bg-color);
  position: sticky;
}

.table-grid-th.th-fixed-left:before, .table-grid-th.th-fixed-right:before {
  content: "";
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.table-grid-th.th-fixed-left:before {
  border-right: 1px solid #ebeef5;
  right: -1px;
}

.table-grid-th.th-fixed-left.th-fixed-left-shadow:before {
  box-shadow: inset 10px 0 10px -10px #00000026;
}

.table-grid-th.th-fixed-right:before {
  border-left: 1px solid #ebeef5;
  left: -1px;
}
