.notif-center-table {
  width: 100%;
  height: 100%;
  position: relative;
}

.notif-center-table .table-grid {
  z-index: 1;
  --th-font-size: 12px;
  --td-font-size: 12px;
  --td-vertical-align: top;
  position: absolute;
  top: 0;
  left: 0;
}

.notif-center-table .table-grid__th.th-mass-actions {
  padding: 0;
}

.notif-center-table .table-grid__th.th-mass-actions .table-grid__cell {
  justify-content: center;
  align-items: center;
  display: flex;
}

.notif-center-table .table-grid__td.td-mass-actions {
  padding: 0;
}

.notif-center-table .table-grid__td.td-mass-actions .table-grid__cell {
  justify-content: center;
  display: flex;
}

.notif-center-table .cell-date {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
}
