.grid-employ-info__digital {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.grid-employ-info__digital .el-input {
  --el-input-height: 32px;
  --el-font-size-base: 12px;
}

.grid-employ-info__digital .el-input__inner::placeholder {
  font-size: var(--el-font-size-base);
}

.grid-employ-info__digital .el-select__wrapper {
  min-height: 24px;
  font-size: 12px;
}

.grid-employ-info__digital .el-tag {
  height: 24px;
}

.grid-employ-info__select-popover .el-checkbox {
  --el-checkbox-font-size: 12px;
}

.grid-employ-info__group {
  flex-direction: column;
  display: flex;
}

.grid-employ-info__group.group-row {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.grid-employ-info__calendar {
  margin-top: 14px;
}

.grid-employ-info .control__label {
  color: #303133;
}
