.head-cell-default {
  justify-content: center;
  display: flex;
}

.head-cell-default:hover .head-cell-default__btn-sort {
  opacity: 1;
  max-width: 100%;
  padding-left: 8px;
}

.head-cell-default__label {
  flex: 1;
}

.head-cell-default__btn-sort {
  cursor: pointer;
  opacity: 0;
  align-items: center;
  max-width: 0;
  transition: all .5s;
  display: flex;
  overflow: hidden;
}

.head-cell-default__btn-sort.--sort-active {
  opacity: 1;
  color: #5229a1;
  max-width: 100%;
  padding-left: 8px;
}

.head-cell-default__btn-sort-icon .ico {
  width: 14px;
  height: 14px;
}
