.head-btn-sort {
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding-top: 1px;
  transition: color .2s;
}

.head-btn-sort .ico {
  width: 16px;
  height: 16px;
  transition: transform .3s;
}

.head-btn-sort:hover {
  color: #303133;
}

.head-btn-sort:hover .ico {
  transform: scale(1.1);
}

.head-btn-sort--asc, .head-btn-sort--asc:hover {
  color: #5229a1;
}

.head-btn-sort--asc:hover .ico {
  transform: scale(1.1);
}

.head-btn-sort--desc {
  color: #5229a1;
}

.head-btn-sort--desc .ico {
  transform: rotate(180deg);
}

.head-btn-sort--desc:hover {
  color: #5229a1;
}

.head-btn-sort--desc:hover .ico {
  transform: rotate(180deg)scale(1.1);
}
