html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

a {
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

html, *, :before, :after {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

img, audio, video {
  max-width: 100%;
  height: auto;
}

audio, canvas, iframe, video, img, svg {
  vertical-align: middle;
}

iframe {
  border: 0;
}

textarea {
  resize: none;
  vertical-align: top;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  overflow: auto;
}

input, textarea, select, button {
  border: none;
  outline: none;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  text-align: left;
  padding: 0;
}

.default-view {
  height: 100%;
  padding: 16px 16px 16px 0;
  position: relative;
}

.h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

.h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}

.h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.control__head {
  cursor: pointer;
  align-items: center;
  gap: 8px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 8px;
  transition: all .2s;
  display: flex;
}

.control__head:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
}

.control__head-label {
  color: #5229a1;
  font-size: 18px;
  line-height: 26px;
}

.control__head-arrow {
  color: #a8abb2;
}

.control__head-arrow .ico {
  width: 16px;
  height: 16px;
  transition: all .2s;
}

.--is-open-group .control__head-arrow .ico {
  transform: rotate(180deg);
}

.control__label {
  padding: 5px 0;
  display: flex;
}

.control--required .control__label {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: .6em;
  position: relative;
}

.control--required .control__label:before {
  content: "*";
  color: red;
  font-size: 1.1em;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
}

.control__label-info {
  transform: translate(20%, -10%);
}

.control__group.group-row {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.control__group.group-row.gap-8 {
  gap: 8px;
}

.control__group.group-row.gap-24 {
  gap: 16px 24px;
}

.control__group.group-row.gap-32 {
  gap: 16px 32px;
}

.control__group.group-row.gap-50 {
  gap: 16px 50px;
}

.control__group.group-row.gap-64 {
  gap: 16px 64px;
}

.control__group.group-row.row-space-between {
  justify-content: space-between;
}

.control__group.group-row.flex-no-wrap {
  flex-wrap: nowrap;
}

.control__group.group-row.align-center {
  align-items: center;
}

.control__group.group-col {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.control__group.group-col.gap-24 {
  gap: 24px;
}

.control__value {
  color: #5229a1;
  font-weight: 700;
}

.control__dots {
  border-bottom: 1px dotted #e4e7ed;
  flex: 1;
  margin: 0 8px;
}

.control__builder .bs-select-v2, .control__builder .bs-single-select {
  width: 60px;
  min-width: 60px;
}

.control.full-free-width {
  flex: 1;
}

.bs-message-box {
  --el-color-primary: #5229a1;
  font-family: "Exo 2", sans-serif;
}

.bs-message-box .el-button {
  --el-button-border-color: #dcdfe6;
  --el-button-hover-text-color: #303133;
  --el-button-hover-bg-color: #f2f2f2;
  --el-button-hover-border-color: #dcdfe6;
  --el-button-active-text-color: #303133;
  --el-button-active-bg-color: #fff;
  --el-button-active-border-color: #a8abb2;
  --el-border-radius-base: 2px;
  font-family: "Exo 2", sans-serif;
}

.bs-message-box .el-button.el-button--primary {
  --el-button-bg-color: #5229a1;
  --el-button-border-color: #5229a1;
  --el-button-hover-text-color: #fff;
  --el-button-hover-bg-color: #633eaa;
  --el-button-hover-border-color: #633eaa;
  --el-button-active-text-color: #fff;
  --el-button-active-bg-color: #422181;
  --el-button-active-border-color: #422181;
  --el-button-outline-color: transparent;
}

.bs-message-box .el-button:disabled {
  --el-button-disabled-text-color: #c0c4cc;
  --el-button-disabled-bg-color: #fff;
  --el-button-disabled-border-color: #c0c4cc;
}

.el-select-dropdown__wrap {
  max-width: 600px;
}

:root {
  --el-color-primary: #5229a1;
  --el-font-family: Exo 2, sans-serif;
}

body {
  color: #303133;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  background-color: #f2f3f5;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 4px;
  height: 0;
}

body::-webkit-scrollbar-track {
  background-color: #f2f3f5;
  margin: 0;
}

body::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 0;
}

body::-webkit-resizer {
  background-repeat: no-repeat;
  width: 3px;
  height: 0;
}

input {
  font-family: "Exo 2", sans-serif;
}

input::placeholder {
  font-family: "Exo 2", sans-serif;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
