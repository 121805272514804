.nt-cell-action__type {
  align-items: center;
  gap: 8px;
  display: flex;
}

.nt-cell-action__type .bs-btn {
  border-radius: 2px;
  height: 24px;
  margin: 0;
  padding: 0 12px;
}

.nt-cell-action__type-label {
  color: #fff;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  display: flex;
}

.type-agreement-success .nt-cell-action__type-label {
  background-color: #67c23a;
}

.type-agreement-not .nt-cell-action__type-label {
  background-color: #f56c6c;
}

.nt-cell-action__type-date {
  color: #909399;
  font-size: 10px;
}
