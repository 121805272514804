.side-select {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.side-select__item {
  border-radius: 4px;
  margin: 0;
  padding: 5px 12px;
  font-size: 14px;
  position: relative;
}

.side-select__item span {
  align-items: center;
  gap: 4px;
  display: flex;
}

.side-select__item .ico {
  width: 11px;
  height: 16px;
}

.side-select__item.--is-light {
  padding: 5px 8px;
}

.side-select__item.--is-active {
  border-color: #00b2ff;
}

.side-select__item.--is-in-target-program {
  background: linear-gradient(0deg, #5229a133 0% 100%), #fff;
}

.side-select__item.--is-not-working {
  color: #c0c4cc;
  border-color: #c0c4cc;
}

.side-select__item.--is-not-working.--is-active {
  border-color: #909399;
}

.side-select__item.--is-not-working .side-select__item-label {
  color: #c0c4cc;
  font-weight: 400;
}

.side-select__item.--is-not-working .side-select__digital-badge {
  background-color: #c0c4cc;
}

.--is-active .side-select__item-label {
  font-weight: 700;
}

.side-select__digital-badge {
  background-color: #5229a1;
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  display: flex;
  position: absolute;
  top: -4px;
  left: 100%;
  transform: translateX(-50%);
}

.side-select__digital-badge .svg-icon {
  width: 60%;
  height: 60%;
}

.side-select__digital-badge .ico {
  width: 100%;
  height: 100%;
}

.side-select .el-button + .el-button {
  margin: 0;
}
