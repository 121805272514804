.loader-spinner {
  -webkit-user-select: none;
  user-select: none;
  z-index: 99;
  background-color: #fffc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-spinner__spinner {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: 1s linear infinite rotate;
  position: relative;
}

.loader-spinner__spinner:before {
  content: "";
  box-sizing: border-box;
  border: 5px solid #909399b3;
  border-radius: 50%;
  animation: 2s linear infinite prixClipFix;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.loader-spinner__title {
  margin-top: 24px;
}

.loader-spinner__num {
  color: #303133;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: calc(50% - 21px);
  left: 50%;
  transform: translate(-50%, -50%);
}
