.bs-btn {
  --el-button-border-color: #dcdfe6;
  --el-button-hover-text-color: #303133;
  --el-button-hover-bg-color: #f2f2f2;
  --el-button-hover-border-color: #dcdfe6;
  --el-button-active-text-color: #303133;
  --el-button-active-bg-color: #fff;
  --el-button-active-border-color: #a8abb2;
  --el-border-radius-base: 2px;
  --el-button-size: 40px;
  height: var(--el-button-size);
  padding: 8px 20px;
}

.bs-btn.el-button--small {
  --el-button-size: 32px;
}

.bs-btn.el-button--primary {
  --el-button-bg-color: #5229a1;
  --el-button-border-color: #5229a1;
  --el-button-hover-text-color: #fff;
  --el-button-hover-bg-color: #633eaa;
  --el-button-hover-border-color: #633eaa;
  --el-button-active-text-color: #fff;
  --el-button-active-bg-color: #422181;
  --el-button-active-border-color: #422181;
}

.bs-btn.is-disabled, .bs-btn:disabled {
  --el-button-disabled-text-color: #c0c4cc;
  --el-button-disabled-bg-color: #fff;
  --el-button-disabled-border-color: #c0c4cc;
}
