.select-add-option__confirm-form {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.select-add-option__btn-actions {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.select-add-option__input .el-input {
  --el-input-height: 32px;
}
