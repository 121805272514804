.marker-type-three {
  --color-side-1: #333;
  --color-side-2: #333;
  --color-side-3: #333;
  --color-side-4: #333;
  --color-status-1: transparent;
  --color-status-2: transparent;
  --color-status-3: transparent;
  --color-status-4: transparent;
  --color-crown-1: transparent;
  --color-crown-2: transparent;
  --color-crown-3: transparent;
  --color-crown-4: transparent;
  --rotate-label: 0deg;
  width: 100%;
  height: 100%;
  position: relative;
}

.marker-type-three__icon {
  width: 115%;
  height: 115%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-three__icon .svg-icon, .marker-type-three__icon .ico {
  width: 100%;
  height: 100%;
}

.marker-type-three__crown {
  opacity: .6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-three__crown-item {
  --color-crown: #67c23a;
  width: 110%;
  height: 110%;
  position: absolute;
}

.marker-type-three__crown-item .svg-icon, .marker-type-three__crown-item .ico {
  width: 100%;
  height: 100%;
}

.marker-type-three__crown-item.crown-item-1 {
  --color-crown: var(--color-crown-1);
  top: 50%;
  left: 130%;
  transform: translate(-50%, -50%)rotate(90deg);
}

.marker-type-three__crown-item.crown-item-2 {
  --color-crown: var(--color-crown-2);
  top: 132%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(180deg);
}

.marker-type-three__crown-item.crown-item-3 {
  --color-crown: var(--color-crown-3);
  top: 52%;
  left: -31%;
  transform: translate(-50%, -50%)rotate(270deg);
}

.marker-type-three__crown-item.crown-item-4 {
  --color-crown: var(--color-crown-4);
  top: -30%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(0);
}

.marker-type-three__label {
  color: #fff;
  transform: translate(-50%, -50%) rotate(var(--rotate-label));
  font-size: 7px;
  line-height: 1;
  position: absolute;
}

.--size-large .marker-type-three__label {
  font-size: 9px;
}

.marker-type-three__label.label-1 {
  top: 49%;
  right: 9%;
}

.--size-small .marker-type-three__label.label-1 {
  right: 5%;
}

.marker-type-three__label.label-2 {
  top: 22%;
  left: 52%;
}

.--size-small .marker-type-three__label.label-2 {
  top: 20%;
  left: 51%;
}

.marker-type-three__label.label-3 {
  top: 50%;
  left: 23%;
}

.--size-small .marker-type-three__label.label-3 {
  left: 22%;
}

.marker-type-three__label.label-4 {
  bottom: 1%;
  left: 52%;
}

.--size-small .marker-type-three__label.label-4 {
  bottom: 0;
}
