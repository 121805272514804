.table-com-panel {
  background-color: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  height: 36px;
  padding: 0 8px;
  display: flex;
  box-shadow: 0 0 6px #0000001f;
}

.table-com-panel.--padding-right {
  padding-right: 16px;
}

.table-com-panel__wrap {
  align-items: center;
  gap: 16px;
  display: flex;
}

.table-com-panel__item {
  cursor: pointer;
  color: #5229a1;
  font-size: 12px;
}

.table-com-panel__item:hover {
  text-decoration: underline;
}

.table-com-panel__item.item-other {
  width: 16px;
  height: 16px;
  transition: all .2s;
}

.table-com-panel__item.item-other .ico {
  width: 16px;
  height: 16px;
  display: block;
  transform: rotate(90deg);
}

.table-com-panel__item.item-other:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
  transform: scale(1.1);
}

.table-com-panel__popover.el-popover {
  min-width: 120px;
  padding: 8px;
}

.table-com-panel__other-wrap {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.table-com-panel__other-item {
  cursor: pointer;
  color: #5229a1;
  font-size: 12px;
  line-height: 22px;
}

.table-com-panel__other-item:hover {
  text-decoration: underline;
}
