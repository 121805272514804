.marker-type-one {
  --color-status-1: transparent;
  --color-status-2: transparent;
  --color-crown-1: transparent;
  --color-crown-2: transparent;
  --color-side-1: #333;
  --color-side-2: #333;
  --rotate-label: 0deg;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -51%)rotate(90deg);
}

.marker-type-one__icon {
  width: 115%;
  height: 115%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -51%);
}

.marker-type-one__icon .svg-icon, .marker-type-one__icon .ico {
  width: 100%;
  height: 100%;
}

.marker-type-one__crown {
  opacity: .6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-one__crown-item {
  --color-crown: #67c23a;
  width: 110%;
  height: 110%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-one__crown-item .svg-icon, .marker-type-one__crown-item .ico {
  width: 100%;
  height: 100%;
}

.marker-type-one__crown-item.crown-item-1 {
  --color-crown: var(--color-crown-1);
  top: -30%;
}

.marker-type-one__crown-item.crown-item-2 {
  --color-crown: var(--color-crown-2);
  top: 130%;
  transform: translate(-50%, -50%)rotate(180deg);
}

.marker-type-one__label {
  color: #fff;
  transform: translate(-50%, -50%) rotate(calc(var(--rotate-label)  - 90deg));
  font-size: 8px;
  line-height: 1;
  position: absolute;
}

.--size-large .marker-type-one__label {
  font-size: 10px;
}

.marker-type-one__label.label-1 {
  top: 28%;
  left: 50%;
}

.marker-type-one__label.label-2 {
  bottom: 7%;
  left: 50%;
}

.--size-large .marker-type-one__label.label-2 {
  bottom: 8%;
}

.--size-small .marker-type-one__label.label-2 {
  bottom: 4%;
}
