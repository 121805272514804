.bs-single-select .el-select__wrapper {
  min-height: 40px;
}

.bs-single-select .el-tag {
  height: 32px;
}

.bs-single-select__opt {
  padding-left: 16px;
  position: relative;
}

.bs-single-select__opt.is-selected {
  --el-color-primary: #5229a1;
}

.bs-single-select__opt.is-hovering {
  --el-fill-color-light: #f6f4fa;
}

.bs-single-select__opt.is-hovering .bs-single-select__opt-btn-remove {
  opacity: .5;
}

.bs-single-select__opt.is-hovering .bs-single-select__opt-btn-remove:hover {
  opacity: 1;
}

.bs-single-select__opt.--show-checkbox .el-checkbox__inner {
  transition: all .1s;
}

.bs-single-select__opt.--show-checkbox.is-hovering .el-checkbox__inner {
  border-color: #5229a1;
}

.bs-single-select__opt.--show-checkbox.is-selected:after {
  content: none !important;
}

.bs-single-select__opt-btn-remove {
  opacity: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  transition: opacity .1s;
  display: flex;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(50%, -50%);
}

.bs-single-select__opt-btn-remove .ico {
  width: 20px;
  height: 20px;
}
