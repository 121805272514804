.notif-user-info {
  --color-name: #ffc473;
  align-items: center;
  gap: 8px;
  display: flex;
}

.notif-user-info__ava {
  align-items: center;
  display: flex;
}

.notif-user-info__ava .bs-avatar {
  --el-avatar-text-size: 14px;
}

.notif-user-info__name {
  color: var(--color-name);
}

.notif-user-info__contacts {
  align-items: center;
  gap: 8px;
  display: flex;
}

.notif-user-info__contact-item {
  cursor: pointer;
  color: #c0c4cc;
  width: 14px;
  height: 14px;
  transition: all .1s;
}

.notif-user-info__contact-item:hover {
  color: #5229a1cc;
  background-color: #5229a10d;
  transform: scale(1.2);
}

.notif-user-info__contact-item .ico {
  width: 14px;
  height: 14px;
}
