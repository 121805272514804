.table-grid-ft .table-grid-td:after {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.table-grid-ft .td-fixed-right, .table-grid-ft .td-fixed-left {
  background-color: var(--ft-bg-color);
}
