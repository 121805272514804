.edit-queue-list {
  flex-direction: column;
  display: flex;
}

.edit-queue-list__head {
  border-bottom: 1px solid #ebeef5;
  justify-content: space-between;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 16px;
  display: flex;
}

.edit-queue-list__head.pd-right {
  padding-right: 24px;
}

.edit-queue-list__head-check .bs-checkbox {
  height: auto;
}

.edit-queue-list__head-remove {
  cursor: pointer;
  color: #606266;
  transition: all .2s;
}

.edit-queue-list__head-remove:hover {
  color: #5229a1;
  transform: scale(1.05);
}

.edit-queue-list__head-remove:active {
  transform: scale(.98);
}

.edit-queue-list__head-remove .ico {
  width: 16px;
  height: 16px;
}

.edit-queue-list__body {
  flex-grow: 1;
  min-height: 80px;
  max-height: 380px;
  overflow-y: auto;
}

.edit-queue-list__body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.edit-queue-list__body::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 4px;
  height: 0;
}

.edit-queue-list__body::-webkit-scrollbar-track {
  background-color: #f2f3f5;
  margin: 0;
}

.edit-queue-list__body::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 4.8px;
}

.edit-queue-list__body::-webkit-resizer {
  background-repeat: no-repeat;
  width: 3px;
  height: 0;
}

.edit-queue-list__empty {
  color: #909399;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 8px 16px;
  font-weight: 500;
  display: flex;
}

.edit-queue-list__item:nth-of-type(odd) {
  background-color: #f5f7fa;
}

.edit-queue-list__item-info {
  flex: 1;
}
