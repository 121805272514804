.operator-detail-table {
  width: 100%;
  height: 100%;
  position: relative;
}

.operator-detail-table__container {
  z-index: 1;
  --el-fill-color-lighter: #f5f7fa;
  --el-table-header-bg-color: #f5f7fa;
  --el-table-row-hover-bg-color: #ebeef5;
  --el-table-fixed-left-column: inset 10px 0 10px -10px #00000026;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.operator-detail-table__container .el-table__cell {
  padding: 16px 0;
}

.operator-detail-table__container .el-table__cell .cell {
  padding: 0 16px;
}

.operator-detail-table__container .el-table__row.hover-row .el-table__cell {
  border-right: 1px solid #fff;
}

.operator-detail-table__container .el-table__header .th-month-active {
  background-color: #c6e2ff;
}

.operator-detail-table__container .el-table__header .el-table__cell {
  padding: 13px 0;
  font-weight: 500;
}

.operator-detail-table__container .el-table__header .el-table__cell .cell {
  padding: 0 16px;
}

.operator-detail-table__container .el-table__header .el-table_1_column_1 {
  padding: 13px 0;
}

.operator-detail-table__container .el-table__header .el-table_1_column_1 .cell {
  padding: 0 12px;
}

.operator-detail-table__container .el-table_1_column_1 {
  padding: 16px 0;
}

.operator-detail-table__container .el-table_1_column_1 .cell {
  padding: 0 12px;
}

.operator-detail-table__container .el-table.is-scrolling-middle .el-table-fixed-column--left {
  border-right: 1px solid var(--el-fill-color-lighter);
}

.operator-detail-table__container .el-table__footer .cell {
  margin-top: -8px;
  margin-bottom: -8px;
  padding: 0 16px;
}

.operator-detail-table__th.el-table__cell {
  padding: 16px 0;
}

.operator-detail-table__th.th-fill-blue {
  background-color: #c6e2ff !important;
}

.operator-detail-table__th.th-fill-violet {
  background-color: #ecd5ff !important;
}

.operator-detail-table__th .cell {
  overflow: unset;
  line-height: 1;
}

.operator-detail-table__td.td-design-info .cell {
  overflow: unset;
}
