.notify-popup {
  color: #303133;
  padding: 0;
}

.notify-popup .el-dialog__header {
  padding: 16px 20px 16px 16px;
}

.notify-popup .el-dialog__headerbtn {
  top: 3px;
}

.notify-popup .el-dialog__title {
  font-weight: 500;
}

.notify-popup .el-dialog__body {
  padding: 16px;
}

.notify-popup .el-dialog__close {
  --el-color-primary: #5229a1;
}

.notify-popup__action-buttons {
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
  display: flex;
}

.notify-popup__action-buttons .bs-btn {
  --el-button-font-weight: 400;
  border-radius: 2px;
  margin: 0;
  font-size: 14px;
}

.notify-popup__header {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.notify-popup__header .ico {
  width: 18px;
  height: 18px;
}

.notify-popup__header .check-green {
  color: #67c23a;
}

.notify-popup__header .check-red {
  color: #b90000;
}

.notify-popup__header p {
  font-size: 18px;
}

.notify-popup__body {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.notify-popup__body__select {
  flex-direction: column;
  gap: 5px;
  display: flex;
}
