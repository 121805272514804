.search-panel {
  padding-left: 32px;
  display: flex;
  position: relative;
}

.search-panel.--is-focus-panel .btn-action, .search-panel:hover .btn-action {
  border-color: #5229a1;
}

.search-panel__btn-action {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.search-panel__btn-action .btn-action {
  border-color: #dcdfe6;
  transition: all .3s;
}

.search-panel__input {
  z-index: 2;
  flex: 1;
  position: relative;
}

.search-panel__input .bs-input {
  --el-input-height: 36px;
  --el-input-hover-border-color: #5229a1;
  --el-font-size-base: 12px;
}

.search-panel__input .el-input__wrapper {
  padding: 1px 12px;
}

.search-panel__input .el-input__inner::placeholder {
  font-size: var(--el-font-size-base);
}
