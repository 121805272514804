.btn-head-notif {
  --el-button-text-color: #303133;
  --el-button-hover-text-color: #5229a1;
  --el-button-hover-bg-color: #5229a10d;
  border: none;
}

.btn-head-notif .ico {
  fill: currentColor;
  width: 16px;
  height: 16px;
  display: block;
}
