.bs-date-picker__popover {
  --el-color-primary: #5229a1;
}

.bs-date-picker__popover .el-month-table td.disabled.today .cell {
  color: #5229a1;
}

.bs-date-picker__popover .el-month-table td.current:not(.disabled) .cell {
  color: #fff;
  background-color: #5229a1;
  font-weight: 500;
}
