.bs-input {
  --el-input-height: 40px;
  --el-input-text-color: #303133;
  --el-color-primary: #5229a1;
}

.bs-input .el-input__inner::placeholder {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
}

.bs-input .el-textarea__inner {
  padding: 8px 12px;
}
