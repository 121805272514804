.marker-type-indor {
  --color-status: #67c23a;
  --color-side: #1f3d8a;
  --rotate-label: 0deg;
  width: 100%;
  height: 100%;
  position: relative;
}

.marker-type-indor__icon {
  width: 115%;
  height: 115%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-type-indor__icon .svg-icon, .marker-type-indor__icon .ico {
  width: 100%;
  height: 100%;
}

.marker-type-indor__label {
  color: #fff;
  transform: translate(-50%, -50%) rotate(var(--rotate-label));
  font-size: 10px;
  line-height: 1;
  position: absolute;
  top: 49%;
  left: 50%;
}
