.bs-multi-select .el-select__wrapper {
  min-height: 40px;
}

.bs-multi-select .el-select__selection.is-near {
  margin-left: -4px;
}

.bs-multi-select .el-tag {
  height: 24px;
}

.bs-multi-select__opt {
  padding-left: 16px;
}

.bs-multi-select__opt.is-selected {
  --el-color-primary: #5229a1;
}

.bs-multi-select__opt.is-hovering {
  --el-fill-color-light: #f6f4fa;
}

.bs-multi-select__opt.--show-checkbox .el-checkbox__inner {
  transition: all .1s;
}

.bs-multi-select__opt.--show-checkbox.is-hovering .el-checkbox__inner {
  border-color: #5229a1;
}

.bs-multi-select__opt.--show-checkbox.is-selected:after {
  content: none !important;
}
